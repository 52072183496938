<template>
  <div class="detail-container">
    <!-- 顶部 -->
    <!-- <div class="fr">
      <el-button @click="goBack" plain size="small"><i class="el-icon-arrow-right" />返回</el-button>
    </div> -->
    <div class="detail-top">
      <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="80px">
        <el-form-item label="视频名称" prop="title">
          <el-input v-model="ruleForm.title" placeholder="请输入名称" size="small" class="sm-long"></el-input>
        </el-form-item>
        <el-form-item v-if="type == '4'" label="类别" prop="diet_cate">
          <el-select v-model="ruleForm.diet_cate" placeholder="请选择" size="small" class="input">
            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="价格" prop="type">
          <el-radio v-model="ruleForm.type" :label="1">免费</el-radio>
          <el-row>
            <el-col :span="3">
              <el-radio v-model="ruleForm.type" :label="2">付费</el-radio>
            </el-col>
            <el-col v-if="ruleForm.type==2" :span="3">
              <el-form-item prop="price">
                <el-input v-model="ruleForm.price" :disabled="ruleForm.type == 1?true:false" :controls="false" size="small" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item> -->
        <el-form-item label="文章内容" prop="content">
          <el-input v-show="false" v-model="ruleForm.content" />
          <div class="editor" style="line-height:22px;">
            <UE v-if="ue" ref="ue" :default-msg="defaultMsg" :config="config" />
          </div>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="goBack" plain size="small"><i class="el-icon-arrow-right" />返回</el-button>
      </div>
    </div>
    <div class="ml-20">
      <span class="mr-50">
        <el-button @click="goBack">取 消</el-button>
      </span>
      <span>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </div>
  </div>
</template>
<script>
import UE from '../../../components/editor'
import {
  uploadFile,
  editOneClocksInfo,
  addClocksInfo,
  getOneClocksInfo
} from '../../../api/api'
export default {
  components: {
    UE
  },
  data () {
    var checkPirce = (rule, value, callback, text) => {
      this.common.Validate.checkPrice2(rule, value, callback, '价格')
    }
    var checkContent = (rule, value, callback, text) => {
      // 获取富文本内容
      const contentUE = this.$refs.ue.getUEContent()
      if (contentUE !== '' && contentUE !== undefined) {
        this.ruleForm.content = contentUE
        callback()
      } else {
        callback(new Error('请填写通知内容'))
      }
    }
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      defaultMsg: '', // 富文本默认值
      config: {
        initialFrameWinform_idth: null,
        initialFrameHeight: 350
      },
      ruleForm: {
        title: '', // 名称
        price: '', // 价格
        type: '', // 价格类型
        content: '', // 文本
        category_id: '',
        category_name: '',
        content_type: '',
        diet_cate: ''
      },
      rules: {
        title: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请输入视频名称'
          }
        ],
        price: [
          {
            required: true,
            trigger: ['blur', 'change'],
            validator: checkPirce
          }
        ],
        header_img: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请上传视频封面'
          }
        ],
        content: [
          { required: true, trigger: 'change', validator: checkContent }
        ],
        type: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '请选择是否免费'
          }
        ]
      },
      typeList: [// 类别列表
        {
          value: '2',
          label: '食物属性'
        },
        {
          value: '3',
          label: '生活建议'
        }
      ],
      fileUploadUrl: '',
      ue: false
    }
  },
  created () {
    this.fileUploadUrl = uploadFile
  },
  mounted () {
    if (this.id !== '') {
      this.getInfo()
    } else {
      this.ue = true
    }
  },
  methods: {
    goBack () {
      this.$router.push({
        name: 'healthTrain',
        query: {
          type: this.type
        }
      })
      // this.$router.go(-1)
    },
    // 获取详情信息
    getInfo () {
      this.$axios.post(getOneClocksInfo, { option_id: this.id }).then(
        (res) => {
          if (res.data.code == '1') {
            var form = {
              title: res.data.data.title, // 文章标题
              type: res.data.data.type, // 是int是否付费，1免费 2付费
              price: res.data.data.price, // 付费价格
              diet_cate: res.data.data.diet_cate,
              content: res.data.data.content // 文章内容
            }
            this.ruleForm = form
            this.defaultMsg = res.data.data.content
            this.ue = true
          }
        },
        (err) => {
          console.log(err)
          // this.$message.error()
        }
      )
    },
    // 表单提交事件
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var db = this.ruleForm
          db.pid = this.type
          var url = ''
          if (this.id !== '') {
            db.option_id = this.id
            // 修改
            url = editOneClocksInfo
          } else {
            // 新增
            url = addClocksInfo
          }
          // console.log(db)
          this.$axios.post(url, db).then(
            (res) => {
              if (res.data.code == '1') {
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
                this.goBack()
              }
            },
            (err) => {
              this.$message.error(err.msg)
            }
          )
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
